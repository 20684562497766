'use client';

import { Roboto } from 'next/font/google';

import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import { LIGHT_PALETTE } from './palette/lightPalette';

const ROBOTO_FONT = Roboto({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
});

export const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: ROBOTO_FONT.style.fontFamily,
    },
    palette: LIGHT_PALETTE,
    shape: {
      borderRadius: 12,
    },
  }),
);
