import type { TThemePalette } from './palette.type';

export const LIGHT_PALETTE: TThemePalette = {
  mode: 'light',
  common: { black: '#000000', white: '#ffffff' },
  primary: {
    main: '#003761',
    light: '#335F80',
    dark: '#002643',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#BDBDBD',
    light: '#CACACA',
    dark: '#848484',
    contrastText: '#000000',
  },
  error: {
    main: '#BA1A1A',
    light: '#FFDAD6',
    dark: '#410002',
    contrastText: '#fff',
  },
  warning: {
    main: '#FFD700',
    light: '#FFE866',
    dark: '#CCAC00',
    contrastText: '#000000',
  },
  info: {
    main: '#0288D1',
    light: '#E9F3FC',
    dark: '#014175',
    contrastText: '#fff',
  },
  success: {
    main: '#1e8727',
    light: '#C4FBC8',
    dark: '#07690F',
    contrastText: '#fff',
  },
  grey: {
    '50': '#FAFAFA',
    '100': '#F5F5F5',
    '200': '#E7E7E7',
    '300': '#D7D7D7',
    '400': '#BDBDBD',
    '500': '#9E9E9E',
    '600': '#757575',
    '700': '#616161',
    '800': '#424242',
    '900': '#212121',
    A100: '#F5F5F5',
    A200: '#E7E7E7',
    A400: '#BDBDBD',
    A700: '#616161',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: '#000000',
    secondary: '#000000',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  divider: '#A9A9A9',
  background: { paper: '#ffffff', default: '#fafafafa' },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: '#A9A9A9',
    disabledOpacity: 0.38,
    focus: '#A9A9A9',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
};
